<template>
    <v-container
        fluid
        @drop.prevent="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragend.prevent="dragover = false"
    >
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <PageLayout >
                <template>
                    <v-row>
                        <v-col cols="12">
                            <v-row class="flex-nowrap">
                                <v-col cols="12">
                                    <span v-if="!show_title">
                                        <h1 v-if="title && title !== 'null'">{{ title }}</h1>
                                        <h1 v-else class="client_no_text">{{ $t('enter_text') }}</h1>
                                    </span>
                                    <span v-else>
                                        <ValidationProvider ref="title" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('title')"
                                                v-model="title" type="text" :error-messages="errors"
                                                :disabled="loading || !is_author"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </span>
                                    <v-icon :disabled="userBlock" v-if="!loadingTask && !show_title" :color="favorite ? 'primary' : ''" size="20" class="mb-2 ml-2" @click="favoriteUpdate()">
                                        {{ favorite ? 'mdi-star' : 'mdi-star-outline' }}
                                    </v-icon>
                                    <v-icon :disabled="userBlock" size="18" @click="share" v-if="isShareAvailable" class="mb-2 ml-2">mdi-share-variant-outline</v-icon>
                                    <v-icon :disabled="userBlock" v-if="!loadingTask && !show_title && is_author" @click="show_title=true" size="20" class="mb-2 ml-2" :title="$t('edit')">mdi-pencil mdi-18px</v-icon>
                                    <v-icon :disabled="userBlock || loading" v-if="!loadingTask && !show_title" @click="translation(title)" size="20" class="mb-2 ml-2" :title="$t('translate')">mdi-google-translate</v-icon>
                                    <v-icon :disabled="userBlock || loading" v-if="!loadingDeal" @click="approvalSheet" size="20" class="mb-2 ml-2" :title="$t('confirmation_sheet')">mdi-clipboard-list-outline</v-icon>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>



                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-row class="border_bottom_6 px-3 pb-3" :style="'border-color: '+status_color+' !important;'">
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="author && author.name">
                                            <v-list subheader two-line class="background_none py-0 margin_top_6">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto" @click="editAuthorDialog()">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="author.photo !== '/img/avatar.png'" :src="author.photo" :alt="author.name">
                                                        <v-icon v-else color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="author.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('director')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="responsible && responsible !== null && responsible !== undefined && responsible.name !== null">
                                            <v-list :disabled="userBlock" subheader two-line class="background_none py-0 margin_top_6">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto" @click="dialogAddParticipant = true">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="responsible.photo !== '/img/avatar.png'" :src="responsible.photo" :alt="responsible.name">
                                                        <v-icon v-else class="settings_icon_client" color="primary" size="36">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="responsible.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('responsible')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>

                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" :sm="deadline ? 2 : 3" v-if="created_at">
                                            <v-list class="background_none py-0">
                                                <v-list-item class="mx-0 px-0">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="$t('date_created')"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$moment(created_at).format('DD.MM.YYYY HH:mm')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="2" v-if="deadline">
                                            <v-list :disabled="userBlock" class="background_none py-0">
                                                <v-list-item class="mx-0 px-0" @click="is_author ? openDiapogDate() : ''">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="$t('deadline')"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$moment(deadline).format('DD.MM.YYYY HH:mm')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" :sm="deadline ? 2 : 3" v-if="status_name">
                                            <v-list class="background_none py-0">
                                                <v-list-item class="mx-0 px-0">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="$t('status')"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="status_name" :style="'color: '+status_color+' !important;'"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <!-- description -->
                                        <v-col cols="12">
                                            <div class="description_title mb-2">{{ $t('case_description') }}</div>
                                            <div v-if="!description_edit">
                                                <v-hover v-slot="{ hover }">
                                                    <div v-if="description !== '' && description !== 'null' && description !== null" @click="is_author ? description_edit = true :  description_edit = false" class="description_text" :class="is_author ? 'cursor_pointer' : ''">
                                                        {{ description }}
                                                        <v-btn v-if="is_author && hover"
                                                               icon
                                                               x-small
                                                               @click="description_edit = true"
                                                               :disabled="userBlock"
                                                        >
                                                            <v-icon color="primary">
                                                                mdi-pencil mdi-18px
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div v-else>
                                                        <span v-if="!userBlock" class="add_text" @click="description_edit = true">{{ $t("add_text") }}</span>
                                                    </div>

                                                </v-hover>
                                            </div>
                                            <div v-if="description_edit">
                                                <v-card elevation="0" class="m-0 p-0">
                                                    <v-card-text class="my-0 py-0 mx-0 px-0">
                                                        <v-textarea
                                                            v-model="description"
                                                            type="text"
                                                            class="input_textarea"
                                                            auto-grow
                                                            :disabled="loading"
                                                            no-details
                                                            outlined
                                                            hide-details
                                                        ></v-textarea>
                                                        <v-card-actions class="px-0 mx-0">
                                                            <v-btn
                                                                :disabled="userBlock"
                                                                color="primary"
                                                                class="infinity_button"
                                                                @click="(author.id === $auth.user().id) ? CaseAdminTextUpdate() : ''"
                                                            >
                                                                {{ $t('save') }}
                                                            </v-btn>
                                                            <v-btn
                                                                :disabled="userBlock"
                                                                text
                                                                class="infinity_button_text px-0"
                                                                @click="description_edit = false"
                                                            >
                                                                {{ $t('cancel') }}
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card-text>
                                                </v-card>
                                            </div>

                                            <div  v-if="result_text !== '' && result_text !== 'null' && result_text !== null">
                                                <div class="description_title pt-5 mb-2">{{ $t('result_case_description') }}</div>
                                                <div class="description_text cursor_pointer">
                                                    {{result_text}}
                                                </div>
                                            </div>
                                        </v-col>


                                    </v-row>

                                    <v-row v-if="(answered || is_responsible || is_author) && participantDecisionItems && participantDecisionItems.length > 0">
                                        <v-col cols="12">
                                            <div class="approval">
                                                <div class="approval__info">
                                                    <div>
                                                        <v-icon color="green">mdi-check-circle-outline</v-icon>
                                                        {{ $t('confirmed', {'count': accepted}) }}
                                                    </div>
                                                    <div>
                                                        <v-icon color="red">mdi-close-circle-outline</v-icon>
                                                        {{ $t('rejected', {'count': rejected}) }}
                                                    </div>
                                                    <div>
                                                        <v-icon color="grey">mdi-alert-circle-outline</v-icon>
                                                        {{ $t('did_not_make_decision', {'count': not_accepted}) }}
                                                    </div>
                                                </div>
                                                <div class="approval__users">
                                                    <v-list class="my-0 py-0">
                                                        <v-list-item
                                                            class="mx-0 px-0"
                                                            v-for="participant in participantDecisionItems"
                                                            :key="participant.name + participant.id"
                                                        >
                                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                <img v-if="participant.photo !== '/img/avatar.png'" :src="participant.photo" :alt="participant.name">
                                                                <v-icon v-else color="primary" size="22">
                                                                    ${{ 'settingsIcon' }}
                                                                </v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content class="py-0 title_subtitle">
                                                                <v-list-item-title v-text="participant.name"></v-list-item-title>
                                                                <v-list-item-subtitle v-if="(participant.reason && !participant.confirm)" v-text="$t('rejected_text',{reason: participant.reason})"></v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else-if="(participant.confirm)" v-text="$t('accepted')"></v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else v-text="$t('decision_not_adopted')"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>



                                    <v-row>
                                        <v-col cols="12" v-if="is_participant && !status_name && !answered">
                                            <v-btn :disabled="userBlock" @click="editStatusCaseAdmin(1)" class="infinity_button" color="green" dark>
                                                <v-icon class="pr-2">mdi-check-circle-outline</v-icon>
                                                {{ $t('accept') }}
                                            </v-btn>
                                            <v-btn :disabled="userBlock" @click="dialogEditStatusCaseAdmin=true" text plain outlined class="infinity_button_text ml-5">
                                                <v-icon class="pr-2" color="red">mdi-close-circle-outline</v-icon>
                                                {{ $t('reject') }}
                                            </v-btn>
                                        </v-col>

                                        <v-col class="task__actions buttons-block" cols="12" v-if="!status_name && (is_author || is_responsible)" >
                                            <v-btn :disabled="userBlock"  @click="dialogStatusComplete=true" text plain class="infinity_button_text pl-1" color="primary">
                                                {{ $t('complete') }}
                                            </v-btn>
                                        </v-col>


                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>



                    <v-row class="py-0 mt-1">
                        <v-col cols="12" md="6" class="pr-2">
                            <div class="tab-btn" :class="{'active': display_task}">
                                <div class="d-flex" @click="display_open('display_task')">
                                    <div class="mr-auto cursor-pointer_header menu_tab">
                                        {{ $t('task.self') }}{{ totalTasks > 0 ? ': ' + totalTasks : '' }}
                                    </div>
                                    <div v-if="totalTasks <= 0">
                                        <v-icon :disabled="userBlock" color="secondary" @click="linkSubTask" size="20" class="add_icon mr-2">
                                            mdi-link
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="tab-btn" :class="{'active': display_deal}">
                                <div class="d-flex">
                                    <div class="mr-auto cursor-pointer_header menu_tab" @click="display_open('display_deal')">
                                        {{ $t('deal.self') }}{{ dealItems.length > 0 ? ': ' + dealItems.length : '' }}
                                    </div>
                                    <div v-if="dealItems.length <= 0">
                                        <v-icon :disabled="userBlock" color="secondary" @click="linkDeal" size="20" class="add_icon mr-2">
                                            mdi-link
                                        </v-icon>
                                    </div>

                                </div>
                            </div>
                        </v-col>

                        <FormTab v-for="tab in tabs" :key="tab.id"  :tab="tab" @show-tab="showTab"   />
                    </v-row>

                    <FormTabField v-for="tab in tabs"  entity_type="task" :entity_uuid="task_uuid" :key="tab.id"  :tab="tab"  />

                    <v-row class="py-0 mt-1" v-if="display_task">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="px-6">
                                    <v-data-table :headers="task_headers" :items="taskItems" :options.sync="options" :page.sync="page"
                                                  :items-per-page="perPage" :server-items-length="totalTasks"
                                                  :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                                  @page-count="pageCount = options.pageCount = Number($event)"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer

                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass">

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.title="{ item }" class="text">
                                            <v-btn text plain :to="'/task/' + item.uuid + '/show'"  class="cursor-pointer font_weight_600 button_link">
                                                {{ item.title }}
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.task_status="{ item }">
                                            <div>{{ item.task_status }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>

                                        <template v-slot:item.admin.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>

                                    </v-data-table>
                                </v-card-text>
                                <v-card-actions class="pt-0 justify-end px-4">
                                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table"
                                                  :length="pageCount" :disabled="loading"></v-pagination>
                                </v-card-actions>
                            </v-card>

                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_deal">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="px-6">
                                    <v-data-table :headers="deal_headers" :items="dealItems"

                                                  :loading="loading" :locale="lang"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer

                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass2">

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table5">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.number="{ item }">
                                            <div>
                                                <v-btn text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link">
                                                    {{ item.number }}
                                                </v-btn>
                                            </div>
                                        </template>

                                        <template v-slot:item.deal_status="{ item }">
                                            <div>{{ item.deal_status }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>
                                        <template v-slot:item.admin="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="item.admin.name"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>
                                        <template v-slot:item.active="{ item }">
                                            <v-edit-dialog :return-value.sync="item.active" large persistent
                                                           @save="activeDeal(item)" :save-text="$t('save')"
                                                           :cancel-text="$t('cancel')">
                                                <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no') }}</div>
                                                <template v-slot:input>
                                                    <v-select v-model="item.active" :items="itemYN" item-text="name"
                                                              item-value="id"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                              color="primary"
                                                              class="pt-5 elevation-0 rounded-lg input_text"
                                                    ></v-select>
                                                </template>
                                            </v-edit-dialog>
                                        </template>

                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row class="py-0 mt-1">
                        <v-col cols="12">
                            <Messages entity="case_admin" :entity_uuid="$route.params.id" socket-name="Message" />
                        </v-col>
                    </v-row>


                </template>

                <template v-slot:side>
                    <v-col cols="12" class="pa-0">
                        <FormRight v-for="form in forms" :key="form.sort" entity_type="task" :entity_uuid="task_uuid" :form="form" />
                    </v-col>




                    <v-card style="margin-top: 15px">
                        <v-card-title>
                            <div class="participants">{{ $t('time_and_place') }}</div>
                        </v-card-title>
                        <v-card-text class="mt-0 pt-0">


                            <div class="text-info" v-if="case_type">
                                <div class="text-info__label">{{ $t('case_type') }}</div>
                                <div class="text-info__value">
                                    {{ case_type }}
                                </div>
                            </div>
                            <div class="text-info" v-if="location_event">
                                <div class="text-info__label">{{ $t('location_event') }}</div>
                                <div class="text-info__value">
                                    {{ location_event }}
                                </div>
                            </div>
                            <div class="text-info" v-if="start_at">
                                <div class="text-info__label">{{ $t('start') }}</div>
                                <div class="text-info__value">
                                    {{ $moment(start_at).format('DD.MM.YYYY HH:mm') }}
                                </div>
                            </div>
                            <div class="text-info" v-if="end_at">
                                <div class="text-info__label">{{ $t('finish') }}</div>
                                <div class="text-info__value">
                                    {{ $moment(end_at).format('DD.MM.YYYY HH:mm') }}
                                </div>
                            </div>
                            <div class="text-info">
                                <div class="text-info__label">{{ $t('date_created') }}</div>
                                <div class="text-info__value">
                                    <template v-if="created_at">{{ $moment(created_at).format('DD.MM.YYYY HH:mm') }}</template>
                                </div>
                            </div>

                        </v-card-text>
                    </v-card>



                    <v-card style="margin-top: 15px" v-if="selectedReminderItems.length > 0">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('reminder') }}</div>
                        </v-card-title>
                        <v-card-text class="mt-0 pt-0">
                            <div class="text-info d-flex" v-for="item in selectedReminderItems" :key="item.name">
                                <div class="text-info__label mr-auto">
                                    {{ $t('reminder_to_title', {'time': item.name}) }}
                                </div>
                                <v-icon
                                    :disabled="!is_author"
                                    size="18"
                                    color="primary"
                                    @click="deleteReminder(item)"
                                >
                                    mdi-delete-outline
                                </v-icon>
                            </div>
                        </v-card-text>
                    </v-card>



                    <v-card style="margin-top: 45px !important;">

                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('participants') }}</div>
                            <v-icon
                                :disabled="(author.id !== $auth.user().id) || userBlock"
                                size="18"
                                color="secondary"
                                class="add_icon"
                                @click="dialogAddParticipants=true"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text class="mt-0 pt-0">
                            <v-row>
                                <v-col class="sub_participants" v-if="!$vuetify.breakpoint.xlOnly">{{ $t('author') }}</v-col>
                                <v-col cols="12" sm="12" class="py-1" v-if="author && author.name">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <div v-if="$vuetify.breakpoint.xlOnly" class="my-0 sub_participants">{{ $t('author') }}</div>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="author.photo !== '/img/avatar.png'" :src="author.photo" :alt="author.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="author.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="formatPhoneNumber(author.phone)"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>

                                <template v-if="responsible && responsible !== null && responsible !== undefined && responsible.name !== null">
                                    <v-col class="sub_participants" v-if="!$vuetify.breakpoint.xlOnly">{{ $t('responsible') }}</v-col>
                                    <v-col cols="12" class="py-1">
                                        <v-list class="my-0 py-0">
                                            <template>
                                                <div v-if="$vuetify.breakpoint.xlOnly" class="my-0 sub_participants" v-text="$t('responsible')"></div>
                                                <v-list-item class="px-0">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="responsible.photo !== '/img/avatar.png'" :src="responsible.photo" :alt="responsible.name">
                                                        <v-icon v-else color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="responsible.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="formatPhoneNumber(responsible.phone)"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-col>
                                </template>

                                <v-col class="sub_participants" v-if="participants.length > 0">{{ $t('participants') }}</v-col>
                                <v-col cols="12" sm="12" class="py-1" v-for="item in participants" :key="'participants_'+item.id">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-if="false" v-text="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-if="(item.reason && !item.confirm)" v-text="$t('rejected_text',{reason: item.reason})"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else-if="(item.confirm)" v-text="$t('accepted')"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else v-text="$t('decision_not_adopted')"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>


                                <v-col cols="12" sm="12" class="py-1" v-if="uved_show && uved_show.id ">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <div class="my-0 sub_participants">{{ $t('uved.self') }}</div>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="uved_show.photo !== '/img/avatar.png'" :src="uved_show.photo" :alt="uved_show.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title class="cursor-pointer">
                                                        <v-btn text plain :to="'/uved/' + uved_show.uuid + '/show'" class="button_link">
                                                            {{ uved_show.name }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle v-if="uved_show.phone !== null" v-text="formatPhoneNumber(uved_show.phone.phone)"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else v-text="uved_show.bin_inn"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-card>



                    <v-card style="margin-top: 30px">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('files') }}{{ accompanying_document_urls.length > 0 ? ': ' + accompanying_document_urls.length : '' }}</div>
                            <UploadFiles @files-saved="handleFilesSaved"
                                         v-if="!closed"
                                         :disabled="userBlock || (edit_task_type === 2 && task_status !== 1)">
                            </UploadFiles>
                        </v-card-title>
                        <v-card-text class="mt-0 pt-0" v-if="accompanying_document_urls.length > 0">
                            <v-list>
                                <v-list-item outlined class="file_item px-0" v-for="file in accompanying_document_urls" :key="file.id + '_' + Date.now()">
                                    <v-list-item-icon>
                                        <v-img
                                            max-width="45"
                                            :alt="`${file.name}`"
                                            :src="file.url"
                                            max-height="30"
                                        ></v-img>
                                        <v-icon
                                            x-large
                                            size="10"
                                            color="primary"
                                            v-if="!mimeTypeImage(file.type)">mdi-file-document-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="file_text">
                                        <v-list-item-title>
                                            <ContextMenuFile :file="file" @add-document-form ="addDocumentForm"  @open-file="openFileContext"></ContextMenuFile>
                                       </v-list-item-title>
                                        <v-list-item-subtitle v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="file.author.name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                                        <v-icon color="primary" class="mr-2" :title="$t('download')" @click="downloadFile(file)">mdi-cloud-upload</v-icon>
                                        <v-icon vcolor="primary" v-if="file.uuid && (is_author || can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteFileServer(file)">mdi-delete-outline</v-icon>
                                        <v-icon color="primary" v-if="!file.uuid && (is_author || can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteAccompanyingDocumentFile(file)">mdi-delete-outline</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                    </v-card>


                    <v-col class="py-0" cols="12">
                        <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                        <v-file-input
                            hide-input prepend-icon=""

                            :label="$t('accompanying_documents')"
                            v-model="accompanying_documents"
                            id="accompanying_documents"
                            multiple
                            @change="accompanyingDocumentsPreviewFiles"
                        >
                        </v-file-input>
                    </v-col>

                    <v-col class="py-0" cols="12">
                        <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                        <v-file-input hide-input prepend-icon=""
                                      v-model="files"
                                      id="file"
                                      multiple
                                      @change="previewFiles"
                        >
                        </v-file-input>
                    </v-col>
                </template>
            </PageLayout>
        </ValidationObserver>



        <v-dialog
            v-model="dialog_file"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            max-width="500px"
            class="dialog_body"

        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialog_file = false;document_urls=[];documents=[]">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text style="height: 300px;" class="pa-0">
                    <v-list subheader>
                        <v-list-item v-for="document in document_urls" :key="document.id">
                            <v-list-item-icon>
                                <v-img
                                    max-width="90"
                                    :alt="`${document.name}`"
                                    :src="document.url"
                                    max-height="60"
                                ></v-img>
                                <v-icon
                                    x-large
                                    v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                <v-btn
                                    icon
                                    @click="deleteFile(document)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>

                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="SentMessageFile">
                        {{ $t('sent') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="accompanying_documents_dialog"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            max-width="500px"
            class="dialog_body"

        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('send_files', {'count': accompanying_documents_preview.length}) }}</span>
                    </div>
                    <div>
                        <v-icon @click="accompanying_documents_dialog = false;accompanying_documents_preview=[];accompanying_documents=[]">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text style="height: 300px;" class="pa-0">
                    <v-list subheader>
                        <v-list-item v-for="document in accompanying_documents_preview" :key="document.id">
                            <v-list-item-icon>
                                <v-img
                                    max-width="90"
                                    :alt="`${document.name}`"
                                    :src="document.url"
                                    max-height="60"
                                ></v-img>
                                <v-icon
                                    x-large
                                    v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                <v-btn
                                    icon
                                    @click="deleteAccompanyingDocuments(document)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>

                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveTaskFile">
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogEditStatusCaseAdmin"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('reason_for_rejecting') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogEditStatusCaseAdmin = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                            v-slot="{ errors, valid }">
                            <v-textarea
                                :label="$t('write_reason_for_the_rejection')"
                                v-model="reject_text"
                                :error-messages="errors"
                                :disabled="loading"
                                rows="8"
                                color="primary"
                                auto-grow
                                clearable outlined

                                full-width
                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                            >
                            </v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-end pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="red"
                        @click="editStatusCaseAdmin(0)"
                    >{{ $t('reject') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogLinkDeal"
        >
            <ValidationObserver ref="observerDialogLinkDeal" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('link_deal') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogLinkDeal = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <v-col class="py-0 pt-5" cols="12" sm="12">
                            <ValidationProvider ref="deal" rules="required|min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{ $t('deal.self') }}</div>
                                <v-autocomplete v-model="deal" :items="dealAddItems"
                                                :error="!valid"
                                                :search-input.sync="dealSearching"
                                                item-text="number" item-value="id"
                                                return-object
                                                :loading="loadingDeals"
                                                :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                :label="$t('deal.self')"
                                                @click:clear="dealSearching=null;deal=null;dealAddItems=[];"
                                                @click="clearResponsibles(deal)"
                                                autocomplete="off"
                                                hide-details
                                                :error-messages="errors"
                                                :disabled="loadingDeals"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span>{{ item.number + ' ' + ((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '') }}</span>
                                            </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span>{{ item.number + ' ' + ((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '') }}</span>
                                            </span>
                                    </template>
                                </v-autocomplete>

                            </ValidationProvider>
                        </v-col>


                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary"  :disabled="invalid || loading" @click="addCaseAdminToDeal()">
                            {{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>


        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialogAddParticipants"
            v-if="dialogAddParticipants"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('edit_participants') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogAddParticipants = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col class="py-0 pt-5" cols="12">
                            <ValidationProvider ref="responsibleAdd" rules="required|min:1" v-slot="{ errors, valid }">
                                <UserSelection
                                    v-model="responsibleAdd"
                                    id="responsible"
                                    :label="$t('responsible')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12">
                            <ValidationProvider ref="participantsAdd" rules="min:1" v-slot="{ errors, valid }">
                                <UserSelection
                                    multiple
                                    v-model="participantsAdd"
                                    id="participants"
                                    :label="$t('participants')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />

                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12">
                            <ValidationProvider ref="uved" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{ $t('uved.self') }}</div>
                                <v-autocomplete v-model="uved" :items="uvedItems"
                                                :error="!valid"
                                                :search-input.sync="uvedSearching"
                                                item-text="name" item-value="id"
                                                return-object
                                                :loading="loadingUveds"
                                                :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                :label="$t('uved.self')"
                                                @click:clear="uvedSearching=null;uved=null;uvedItems=[];companyItems = [];company = null;"
                                                @click="clearUveds"
                                                @click:append="redirectToUved(uved)"
                                                @change="set_uved = uved"
                                                autocomplete="off"
                                                hide-details
                                                :error-messages="errors"
                                                :disabled="loadingUveds"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.bin_iin"></span>
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.bin_iin"></span>
                                            </span>
                                    </template>
                                </v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="editParticipants()">
                        {{ $t('edit') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialogEditAuthor"
            v-if="dialogEditAuthor"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('change_author') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogEditAuthor = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text>
                    <v-row>


                        <v-col cols="12">
                            <ValidationProvider ref="authorAdd" rules="required" v-slot="{ errors, valid }">
                                <UserSelection
                                    v-model="authorAdd"
                                    :valuel="authorAdd"
                                    id="author"
                                    :label="$t('manager')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />
                            </ValidationProvider>

                        </v-col>

                    </v-row>

                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="editParticipants()">
                        {{ $t('change') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_image_view"
            scrollable
            fullscreen
            v-if="!!image_view.comment"
        >
            <v-card>
                <v-card-title class="px-2 mx-0">
                    <span class="pl-5 text-truncate">{{ image_view.comment.file_name }}</span>
                    <v-spacer></v-spacer>
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on"
                                   @click="downloadFileMessage(image_view.comment.value, image_view.comment.file_name)"
                                   icon>
                                <v-icon>mdi-arrow-collapse-up mdi-flip-v</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('download') }}</span>
                    </v-tooltip>
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on"
                                   @click="ImageViewClose()"
                                   icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('close') }}</span>
                    </v-tooltip>
                </v-card-title>

                <v-card-text style="height: 300px;">

                    <v-container fluid>
                        <v-row dense>
                            <v-col>
                                <v-card>
                                    <v-img
                                        :alt="`${image_view.comment.file_name}`"
                                        :src="image_view.comment.value"


                                    >

                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>


                </v-card-text>


            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialogAddParticipant"
            v-if="dialogAddParticipant"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('edit_participants') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogAddParticipant = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col class="py-0 pt-5" cols="12">
                            <ValidationProvider ref="responsibleAdd" rules="required|min:1" v-slot="{ errors, valid }">
                                <UserSelection
                                    v-model="responsibleAdd"
                                    :valuel="responsibleAdd"
                                    id="responsible2"
                                    :label="$t('responsible')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />
                            </ValidationProvider>
                        </v-col>

                    </v-row>

                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="editParticipants()">
                        {{ $t('edit') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogLinkTask"
        >
            <ValidationObserver ref="observerDialogLinkTask" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('link_task') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogLinkTask = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <v-col class="py-0 pt-5" cols="12" sm="12">
                            <ValidationProvider ref="task" rules="required|min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{ $t('tasks') }}</div>
                                <v-autocomplete v-model="task" :items="taskAddItems"
                                                :error="!valid"
                                                :search-input.sync="taskSearching"
                                                item-text="title" item-value="id"
                                                return-object
                                                :loading="loadingTasks"
                                                :no-data-text="taskSearching ? $t('nothing_found_by',{'search': taskSearching}) : $t('nothing_found_name')"
                                                :label="$t('responsible')"
                                                @click:clear="taskSearching=null;task=null;taskAddItems=[];"
                                                @click="clearResponsibles(task)"
                                                autocomplete="off"
                                                hide-details
                                                :error-messages="errors"
                                                :disabled="loadingTasks"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.title"></span>
                                            </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.title"></span>
                                            </span>
                                    </template>
                                </v-autocomplete>

                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" :disabled="invalid || loading" @click="addCaseAdminToTask()">
                            {{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogStatusComplete"
            class="dialog_body"
        >
            <ValidationObserver ref="observerDialogStatusComplete" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('write_report') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogStatusComplete = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">

                        <v-col cols="12" sm="12">
                            <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                                v-slot="{ errors, valid }">
                                <v-textarea
                                    type="text"
                                    row-height="25"
                                    no-details
                                    hide-details
                                    full-width

                                    :label="$t('write_report')"
                                    v-model="result_text"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    rows="8"
                                    color="primary"
                                    auto-grow
                                    clearable outlined
                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                >
                                    <template v-slot:append-outer>
                                        <v-icon @click="document.getElementById('case_file').click()">mdi-paperclip</v-icon>
                                    </template>

                                </v-textarea>

                                <v-file-input hide-input prepend-icon=""
                                              v-model="case_files"
                                              id="case_file"
                                              multiple
                                              @change="handleFileUpload"
                                >
                                </v-file-input>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12">

                            <div class="mb-2" v-for="(item, index) in selectedFiles" :key="index">
                                <v-icon
                                    @click="deleteCaseFile(item)"
                                    color="primary"
                                >
                                    mdi-delete
                                </v-icon>
                                {{ item.name }}
                            </div>

                        </v-col>
                        <v-col class="py-2" cols="12" sm="12">
                            <ValidationProvider ref="case_status" rules="required"
                                                v-slot="{ errors, valid }">
                                <v-select v-model="case_status" :disabled="loading"
                                          :items="caseStatusItems" :error-messages="errors"
                                          :error="!valid"
                                          item-text="name" item-value="id"
                                          :label="$t('case_status')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          hide-details
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                          clearable></v-select>
                            </ValidationProvider>
                        </v-col>

                    </v-card-text>
                    <v-card-actions class="justify-end pt-0">
                        <v-btn
                            :disabled="invalid || loading"
                            text
                            color="primary"
                            @click="caseAdminComplete()"
                        >{{ $t('sent') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>

        <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>

        <DocumentFormDialog
            :dialogForm="dialogForm"
            :document_flow_urls_form="document_flow_urls_form"
            :closeForm = "true"
            @dialog-form-close = "dialogFormClose"
        ></DocumentFormDialog>

        <v-overlay :value="dragover" z-index="10" color="#244ba8">
            <v-row>
                <v-col style="height: 400px;width:500px;"
                       class="grey darken-1"
                       @drop.prevent="dropFile"
                >
                    <v-col style="height: 380px;width:490px; border: dashed 4px #fff;">

                        <v-card
                            height="350"
                            class="pa-2 grey darken-1 align-self-center"
                            elevation="0"

                        >
                            <v-col sm="12" class="text-center mt-10">
                                <v-icon
                                    left style="font-size: 150px"

                                    class="text-center">
                                    mdi-file-upload-outline
                                </v-icon>
                            </v-col>
                            <v-col sm="12" class="text-center font-weight-bold h5">{{ $t('drop_files_here_to_send_them') }}</v-col>


                        </v-card>


                    </v-col>
                </v-col>
            </v-row>


        </v-overlay>


    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import PageLayout from "@/components/Leentech/PageLayout.vue"
import FormRight from '@/components/Eav/FormRight.vue';
import FormTab from '@/components/Eav/FormTab.vue';
import FormTabField from '@/components/Eav/FormTabField.vue';
import OpenFile from '@/components/OpenFile.vue';
import UserSelection from "@/components/Form/UserSelection.vue";
import ShareView from "@/plugins/mixins/ShareView"
import Messages from "@/components/Messages.vue"
import ContextMenuFile from "@/components/ContextMenuFile.vue";
import DocumentFormDialog from '@/components/DocumentFormDialog.vue';
import UploadFiles from '@/components/UploadFiles.vue';

export default {
    name: 'CaseShow',
    components: {
        ValidationProvider,
        ValidationObserver,
        PageLayout,
        FormRight,
        FormTab,
        FormTabField,
        OpenFile,
        UserSelection,
        Messages,
        ContextMenuFile,
        DocumentFormDialog,
        UploadFiles
    },
    inject: ['forceRerender'],

    mixins: [
        ShareView
    ],

    data() {
        return {
            dialogForm: false,
            document_flow_urls_form: [],
            forms: [],
            tabs: [],
            heading: null,
            author: {},
            uved_show: {},
            set_uved: {},
            progress: 0,
            loading: false,
            tab: 0,
            id: null,
            number: null,
            task_statusItems: [],
            task_status: 1,
            status_color: '#B3B3B3',
            status_name: null,
            company: null,
            companyItems: [],
            companySearching: null,
            loadingCompanies: false,
            description: "",
            description_edit: false,
            text: null,
            responsible: null,
            responsibleAdd: null,
            participantsAdd: [],
            participants: [],
            auditor: null,
            participantItems: [],
            participantDecisionItems: [],
            aligner: null,
            alignerItems: [],
            alignersAdd: [],
            dragover: false,
            document,
            documents: [],
            files: [],
            document_urls: [],
            dialog_file: false,
            file_urls: [],
            task_uuid: null,
            request: [],
            answer: [],
            blur_stop: 0,
            task_order_total: 0,
            task_order_nds_total: 0,
            task_order_no_nds_total: 0,
            chatMessages: [],
            chatMessagesDate: [],
            toolbar_reply: false,
            task_active: true,
            reply_msg: {},
            reply_name: null,
            reply_uuid: null,
            message_menu: [
                {title: this.$t('delete'), click: "delete"},
                {title: this.$t('reply'), click: "reply"},
            ],

            accompanying_documents: [],
            accompanying_document_urls: [],
            task_documents: [],
            accompanying_documents_preview: [],
            accompanying_documents_dialog: false,
            not_accepted: 0,
            accepted: 0,
            rejected: 0,
            is_aligner: null,
            is_responsible: false,
            is_agreed: false,
            is_urgent: false,
            dialogEditStatusCaseAdmin: false,
            task_document_id: null,
            reject_text: null,
            task_headers: [
                {
                    text: this.$t('task_name'),
                    align: "left",
                    sortable: true,
                    value: "title"
                },
                {
                    text: this.$t('task_status'),
                    sortable: false,
                    value: "task_status"
                },
                {
                    text: this.$t('date'),
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: false,
                    value: "admin.name"
                }
            ],
            taskItems: [],
            totalTasks: 0,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            sortBy: "id",
            sortDir: false,
            display_task: false,
            display_deal: false,
            display_case: false,
            deal_headers: [
                {
                    text: this.$t('number_deal'),
                    align: "left",
                    sortable: true,
                    value: "number"
                },
                {
                    text: this.$t('deal_status'),
                    sortable: false,
                    value: "deal_status"
                },
                {
                    text: this.$t('date'),
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: false,
                    value: "admin"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active"
                },
            ],
            dealItems: [],
            itemYN: [
                {
                    id: -1,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            loadingTaskStatus: false,
            loadingMessages: false,
            loadingCalendarCase: false,
            loadingSubtask: false,
            loadingDeal: false,
            loadingTask: false,
            dialogLinkDeal: false,
            deal: null,
            dealAddItems: [],
            dealSearching: null,
            loadingDeals: false,
            dialogAddParticipant: false,
            dialogAddParticipants: false,
            uved: null,
            uvedItems: [],
            uvedSearching: null,
            loadingUveds: false,
            favorite: false,
            completion_date: null,

            deadline: null,
            day_work: 0,
            dialog_image_view: false,
            image_view: {},
            show_title: false,
            title: null,
            displayDateTimePicker: false,
            loading_type: null,
            type_transport: null,


            error_from_city: '',
            from_city: null,
            error_to_city: '',
            to_city: null,
            special_text: null,
            dangerous_cargo: null,
            stackable_cargo: null,
            show_loading_type: false,
            error_loading_type: '',
            show_from_city: false,
            show_to_city: false,
            show_special_text: false,
            show_dangerous_cargo: false,
            show_stackable_cargo: false,
            edit_task_type: null,



            name: null,
            showSuggestions: false,
            suggestions: [],
            menuX: 0,
            menuY: 0,
            inputAfterAt: "",
            activeIndex: 0,
            heightSuggestions: 150,
            is_overdue: true,
            extend_deadline_uuid: null,
            unlock_text: null,
            change_deadline: null,
            extension_date: null,
            extension_form: false,
            extension_button: false,
            error_weight: '',
            weight: null,
            show_weight: false,
            error_terms_delivery: '',
            terms_delivery: null,
            show_terms_delivery: false,
            show_tr_terms_delivery: false,
            error_place_argo_pickup: '',
            place_argo_pickup: null,
            show_place_argo_pickup: false,
            error_insurance: '',
            insurance: null,
            show_insurance: false,
            field_type_transport: [],
            field_type_transport_text: null,
            field_type_transportItems: [],
            show_field_type_transport: false,
            error_field_type_transport: '',
            dialogEditAuthor: false,
            admin_id: null,
            adminItems: [],
            error_volume: '',
            volume: null,
            show_volume: false,
            error_goods: '',
            goods: null,
            show_goods: false,
            dialogLinkTask: false,
            taskAddItems: [],
            taskSearching: null,
            loadingTasks: false,
            task: null,
            all_agreed: false,
            dialogDelegate: false,
            delegatingToUsers: null,




            created_at: null,
            start_at: null,
            end_at: null,
            menu: false,
            reminderItems: [],
            selectedReminderItems: [],
            location_event: null,
            case_type: null,
            result_text: null,
            is_participant: false,
            answered: false,
            is_author: false,
            authorAdd: null,
            dialogStatusComplete: false,
            selectedFiles: [],
            case_files:[],
            case_status: null,
            caseStatusItems: [],
            dialogOpenFile: false,
            fileOpen: {},









        }
    },
    computed: {
        ...mapGetters(['userBlock', 'language', 'listLanguages', 'lang', 'timezone', 'itemsPerPage', 'perPageItems']),
        languages() {
            return this.listLanguages
        },

        filteredSuggestions() {
            // Фильтрация предложений на основе текста после символа "@"
            let suggestionItems = this.suggestions.filter((suggestion) =>
                suggestion.toLowerCase().includes(this.inputAfterAt.toLowerCase())
            );
            if (suggestionItems.length >= 3) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 150;
                this.menuY = textareaRect.top - 5;
            } else if (suggestionItems.length === 2) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 100;
                this.menuY = textareaRect.top - 4.5;
            } else if (suggestionItems.length === 1) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 50;
                this.menuY = textareaRect.top - 4.9;
            } else if (suggestionItems.length === 0) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 0;
                this.menuY = textareaRect.top - 5.1;
            }
            return suggestionItems;
        },
    },
    async mounted() {
        await this.getReminder()
        await this.checkCreate()
        await this.getCaseStatus()
        await this.getTaps()
        await this.getGroups()
        // await this.getMessages()
        await this.CaseAdminMessagesWebsocket()
        // this.getAdminLogins();

    },
    watch: {
        companySearching: debounce(function (val) {
            if (val && !this.company) {
                this.getCompanies(val)
            }
        }, 500),
        dealSearching: debounce(function (val) {
            if (val) {
                this.getAddDeals(val)
            }
        }, 500),
        uvedSearching: debounce(function (val) {
            if (val && !this.uved) {
                this.getUveds(val)
            }
        }, 500),
        taskSearching: debounce(function (val) {
            if (val) {
                this.getTasks(val)
            }
        }, 500),
    },

    methods: {


        async checkCreate() {
            if (this.$route.name === "task.create") {
                this.heading = this.$t('task_creation')
            } else {
                this.heading = this.$t('task_editing')
                if (this.$route.params.id) {
                    await this.getCaseAdmin()
                }
            }
        },
        async getCaseAdmin() {
            var _this = this;
            this.progress = 0
            this.loadingTask = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/case_admin/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.not_accepted = res.body.data.not_accepted
                    this.accepted = res.body.data.accepted
                    this.rejected = res.body.data.rejected
                    this.is_responsible = res.body.data.is_responsible
                    this.title = res.body.data.name
                    this.author = res.body.data.admin
                    this.authorAdd = res.body.data.admin
                    this.admin_id = res.body.data.admin.id
                    this.responsible = res.body.data.responsible
                    this.participants = res.body.data.participants
                    this.participantItems = res.body.data.participants
                    this.participantDecisionItems = res.body.data.participants
                    this.uved_show = res.body.data.uved
                    this.description = res.body.data.text
                    this.status_name = res.body.data.case_status
                    this.accompanying_document_urls = res.body.data.documents
                    this.start_at = res.body.data.start_at
                    this.end_at = res.body.data.end_at
                    this.created_at = res.body.data.created_at
                    this.location_event = res.body.data.location_event.name
                    this.case_type = res.body.data.case_type.name
                    this.result_text = res.body.data.result_text
                    if(res.body && res.body.data && res.body.data.reminders && res.body.data.reminders.length > 0)
                    {
                        this.selectedReminderItems = res.body.data.reminders
                        this.selectedReminderItems.forEach((item) => {
                            const index = this.reminderItems.findIndex(reminderItem => reminderItem.id === item.id);
                            if (index === -1) {
                                item.disable = true;
                            } else {
                                this.reminderItems[index].disable = item.disable;
                            }
                        });
                    }

                    this.taskItems = res.body.data.task ? [res.body.data.task] : []
                    this.totalTasks = this.taskItems.length
                    this.dealItems = res.body.data.deal ? [res.body.data.deal] : []
                    this.totalDeals = this.dealItems.length
                    this.is_participant = res.body.data.is_participant
                    this.answered = res.body.data.answered
                    this.is_author = res.body.data.is_author
                    this.responsibleAdd = res.body.data.responsible;
                    this.responsibleItems = res.body.data.responsible ? [res.body.data.responsible] : [];
                    this.participantsAdd = res.body.data.participants;
                    this.participantsItems = res.body.data.participants ? [res.body.data.participants] : [];
                    this.uved = res.body.data.uved;
                    this.uvedItems = res.body.data.uved ? [res.body.data.uved] : [];
                    this.updateDisableStatus();

                })
                .catch(err => {
                    this.$router.push({name: 'error-404'});
                    this.$toastr.error(this.$t('failed_to_get_task'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingTask = false
                })
        },
        async getReminder() {
            this.loading = true
            let params = {}
            params.no_main = 1
            await this.$http
                .get("admin/reminder_times", {
                    params: params,
                })
                .then(res => {
                    this.reminderItems = res.body.data
                })
                .catch(err => {
                    this.reminderItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async addReminder(item) {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            formData.append('reminder_time', item.id)


            if (this.$route.params.id) {
                formData.append('case_admin_uuid', this.$route.params.id)
            }

            await this.$http
                .post(`admin/case_admin_reminder`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.selectReminderItem(item);
                    this.$toastr.success(this.$t('case_admin_has_been_updated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    this.menu = false;
                })



        },
        selectReminderItem(item){
            const index = this.selectedReminderItems.indexOf(item);
            if (index === -1) {
                item.disable = true
                this.selectedReminderItems.push(item);
            }
            this.menu = false;
        },
        async deleteReminder(item) {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            formData.append('reminder_time', item.id)


            if (this.$route.params.id) {
                formData.append('case_admin_uuid', this.$route.params.id)
            }

            await this.$http
                .delete(`admin/case_admin_reminder/${this.$route.params.id}/${item.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.deleteReminderItem(item);
                    this.$toastr.success(this.$t('case_admin_has_been_updated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    this.menu = false;
                })



        },
        deleteReminderItem(delete_item) {
            const index = this.selectedReminderItems.indexOf(delete_item);
            if (index > -1) {
                delete_item.disable = false
                this.selectedReminderItems.splice(index, 1);
            }
            const index2 = this.reminderItems.findIndex(item => item.id === delete_item.id);
            if (index2 !== -1) {
                this.reminderItems[index2].disable = false
            }
            this.menu = false;
        },
        updateDisableStatus() {
            const currentDate = new Date();
            const futureDate = new Date(this.start_at);
            // Вычисление разницы в минутах
            const differenceInMinutes = Math.floor((futureDate - currentDate) / (1000 * 60));
            this.reminderItems.forEach(item => {
                item.disable = item.minutes >= differenceInMinutes;
            });
        },
        handleFileUpload() {
            let i = this.selectedFiles.length
            let data = {}
            this.case_files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob =  file
                data.size = file.size
                data.type = file.type
                this.selectedFiles.push(data)
                i++
            })

        },
        deleteCaseFile(item){
            let index =  this.selectedFiles.indexOf(item)
            this.selectedFiles.splice(index, 1)
            if(this.selectedFiles.length === 0){
                this.selectedFiles=[];

            }
        },
        async getCaseStatus() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/case_status", {
                    params: params,
                })
                .then(res => {
                    this.caseStatusItems = res.body.data
                })
                .catch(err => {
                    this.caseStatusItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async caseAdminComplete(){
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('case_admin_uuid', this.$route.params.id)
            }

            if (this.case_status) {
                formData.append('case_status', this.case_status)
            }

            if (this.result_text) {
                formData.append('case_text_result', this.result_text)
            }

            if (this.selectedFiles && this.selectedFiles.length > 0) {
                let is_image = false;
                for (let i in this.selectedFiles) {
                    formData.append(`type_mime[${i}]`, this.selectedFiles[i].type)
                    formData.append(`size[${i}]`, this.selectedFiles[i].size)
                    formData.append(`file_name[${i}]`, this.selectedFiles[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : 0)

                    this.selectedFiles[i].blob =  await this.convertBlobToBase64(this.selectedFiles[i].blob)
                    if (this.selectedFiles[i].blob) {
                        let blob = this.dataURL64toBlob(this.selectedFiles[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.selectedFiles[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.selectedFiles[i].blob)
                }
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            await this.$http
                .post(`admin/case_admin_complete`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.dialogStatusComplete = false;
                    this.$toastr.success(this.$t('case_admin_has_been_updated'))
                    this.selectedFiles = [];
                    this.case_status = null;
                    this.getCaseAdmin()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    this.menu = false;
                })


        },


/////////////////////////

        showDelegateDialog() {
            this.dialogDelegate = true;
        },
        showTab(item){
            this.display_open('close_all')
            this.tabs.forEach(tab => {
                if (tab.name === item.name && tab.tab_id === item.tab_id) {
                    tab.show = true;
                } else {
                    tab.show = false;
                }
            });
        },
        async getTaps(){

            this.loading = true;
            let params = {};

            params.type_form = 'tabs';
            params.entity_type = 'task';
            params.entity_uuid = this.$route.params.id;
            params.sub_entity_type = 'task_type';
            params.sub_entity_id = this.edit_task_type;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.tabs = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_tabs'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getGroups(){

            this.loading = true;
            let params = {};

            params.type_form = 'groups';
            params.entity_type = 'task';
            params.entity_uuid = this.$route.params.id;
            params.sub_entity_type = 'task_type';
            params.sub_entity_id = this.edit_task_type;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.forms = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_groups'))
                })
                .finally(end => {
                    this.loading = false
                })

        },

        async translation(text, comment_uuid = '') {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            formData.append('text', text)
            if (comment_uuid !== '') {
                formData.append('comment_uuid', comment_uuid)
            }
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('case_admin_uuid', this.$route.params.id)
            }


            // Save
            await this.$http
                .post(`admin/translate_text`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res.body.data.case_admin) {
                        this.title = res.body.data.case_admin.name;
                        this.description = res.body.data.case_admin.text;
                    } else if (res.body.data.comment) {
                        let message_date = this.chatMessagesDate[this.$moment(res.body.data.comment.created_at).format('YYYY-MM-DD')];
                        let uuidToFind = res.body.data.comment.uuid;
                        let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                        if (typeof foundObject != "undefined") {
                            foundObject.comment = res.body.data.comment.comment;
                            foundObject.uuid = res.body.data.comment.uuid;
                        }
                    }

                    this.$toastr.success(this.$t('translated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('translation_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        getTranslation(translations) {
            if (!Array.isArray(translations) || translations.length === 0) {
                translations = [];
                return null;
            }
            let translationObject = translations.find(obj => obj && Object.prototype.hasOwnProperty.call(obj, this.language));
            return translationObject ? translationObject[this.language] : null;
        },





        async handleInput() {

            const lastChar = this.text.slice(-1);
            if (lastChar === "@") {
                this.showSuggestions = true;
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.menuX = textareaRect.left;
                // this.menuY = textareaRect.bottom - 220;
                this.activeIndex = 0;

            } else if (lastChar === " ") {
                // Если введен пробел, закрываем меню
                this.showSuggestions = false;
                // Сбрасываем текст после "@" при введении пробела
                this.inputAfterAt = "";
                this.activeIndex = 0;
            } else if (this.showSuggestions) {
                // Если меню открыто и введенный символ не пробел, обновляем текст после "@"
                const atIndex = this.text.lastIndexOf("@");
                this.inputAfterAt = this.text.substring(atIndex + 1);
            }
        },
        selectSuggestion(suggestion) {
            // Заменяем текст в textarea выбранным предложением
            if (this.inputAfterAt.length > 0) {
                this.text = this.text.slice(0, -this.inputAfterAt.length) + suggestion + " ";
            } else {
                this.text = this.text + suggestion + " "
            }
            this.showSuggestions = false;
            // Сбрасываем текст после "@" после выбора предложения
            this.inputAfterAt = "";
            this.activeIndex = 0;
            this.$nextTick(() => {
                this.$refs.textarea.focus();
            });
        },
        handleArrowDown() {
            if (this.activeIndex >= 0 && this.activeIndex < this.filteredSuggestions.length) {
                this.$refs.listItem[this.activeIndex].$el.focus();
                this.activeIndex += 1;
            }
        },
        handleArrowUp() {
            if (this.activeIndex !== 0) {
                this.activeIndex -= 1;
                this.$refs.listItem[this.activeIndex].$el.focus();
            }
        },
        async getAdminLogins() {
            this.loading = true
            let params = {}
            params.active = this.active
            params.perPage = 1000
            params.filter = 'login'
            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.suggestions = res.body.data
                })
                .catch(err => {
                    this.suggestions = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getAuthors() {
            if (this.adminItems.length <= 0) {
                this.loading = true
                let params = {}
                params.active = this.active
                params.perPage = 1000;
                params.sortBy = 'name';
                params.sortDir = 'asc';
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },
        editAuthorDialog() {
            if (this.is_author || this.can(['administrator', 'director', 'general_manager'])) {
                this.getAuthors();
                this.dialogEditAuthor = true
            }
        },
        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        rowClass2() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
        },
        display_open(type) {
            switch (type) {
                case 'display_task':
                    this.display_deal = false;
                    this.display_case = false;
                    this.display_task = (this.display_task) ? false : true;
                    break;
                case 'display_deal':
                    this.display_deal = (this.display_deal) ? false : true;
                    this.display_task = false;
                    this.display_case = false;
                    break;
                case 'display_case':
                    this.display_case = (this.display_case) ? false : true;
                    this.display_task = false;
                    this.display_deal = false;
                    break;
                case 'close_all':
                    this.display_case = false;
                    this.display_task = false;
                    this.display_deal = false;
                    break;
            }
            if(type !== 'close_all'){
                this.tabs.forEach(tab => {
                    tab.show = false;
                });
            }


        },
        async unlockTask() {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            if (this.extension_date) {
                formData.append('extension_date', this.extension_date)
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            // Save
            await this.$http
                .put(`admin/task_unlock/${this.extend_deadline_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.is_overdue = res.body.data.is_overdue;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        clearDeals() {
            if (!this.deal) {
                this.dealAddItems = []
            }
        },
        clearEmptyDealItems(items) {
            if (items && items.length > 0) {
                this.deal = items.filter(item => typeof item === 'object')
            }
        },
        showDeal(item) {
            this.$router.push({
                name: 'deal.show',
                params: {
                    id: item.uuid
                }
            })
        },
        showInput(field) {
            if (this.userBlock === false) {
                const show = 'show_' + field;
                if (this.$data[show]) {
                    this.$data[show] = false
                } else {
                    this.$data[show] = true;
                }
            }

        },
        saveField(field) {
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
            }
            this.saveCaseAdminField(field, this.$data[field]);
        },
        changeField(field) {
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
            }
            if (this.$data[field] && this.$data[field].id) {
                this.saveCaseAdminField(field, this.$data[field].id);
            } else {
                this.saveCaseAdminField(field, this.$data[field]);
            }
        },
        async saveCaseAdminField(field, value) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append(field, value)

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/case_admin/${this.$route.params.id}/field`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('case_admin_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }

        },
        updateDeadline(field, value) {
            this.displayDateTimePicker = false;
            this.saveCaseAdminField(field, value);
        },
        async favoriteUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            formData.append('type', 'task')

            await this.$http
                .put(`admin/favorite/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('favorite_has_been_updated'))
                    if (this.favorite) {
                        this.favorite = false;
                    } else {
                        this.favorite = true
                    }
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('favorite_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        goToUved(uved_show) {
            this.$router.push({
                name: 'uved.show',
                params: {
                    id: uved_show.uuid
                }
            })
        },
        clearUveds() {
            if (!this.uved) {
                this.uvedItems = []
            }
        },
        redirectToUved(val) {
            if (val) {
                this.$router.push({
                    name: 'uved.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getUveds(str) {
            if (str) {
                this.loadingUveds = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.uved = str
                }
                await this.$http
                    .get("admin/uved", {
                        params: params,
                    })
                    .then(res => {
                        this.uvedItems = res.body.data
                    })
                    .catch(err => {
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                        this.$nextTick(() => {
                            if (this.$refs.uved) {
                                this.$refs.uved.$el.querySelector('input').focus();
                            }
                        });
                    })
            }
        },
        async editParticipants() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.$route.params.id) {
                formData.append('case_uuid', this.$route.params.id)
            }

            if (this.responsibleAdd) {
                if (this.responsibleAdd.id) {
                    formData.append('responsible', this.responsibleAdd.id)
                } else {
                    formData.append('responsible', this.responsibleAdd)
                }
            }

            if (this.uved) {
                if (this.uved.id) {
                    formData.append('uved', this.uved.id)
                } else {
                    formData.append('uved', this.uved)
                }
            }

            if (this.authorAdd) {
                if (this.authorAdd.id) {
                    formData.append('author', this.authorAdd.id)
                } else {
                    formData.append('author', this.authorAdd)
                }
            }


            if (this.participantsAdd && this.participantsAdd.length > 0) {
                for (let i in this.participantsAdd) {
                    if (this.participantsAdd[i].id !== undefined && this.participantsAdd[i].id > 0) {
                        formData.append(`participants[${i}]`, this.participantsAdd[i].id)
                    }
                }
            }


            // Add
            await this.$http
                .put('admin/case_admin_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('case_admin_has_been_updated'))
                    this.dialogAddParticipants = false;
                    this.dialogEditAuthor = false;
                    this.dialogAddParticipant = false;
                    this.responsible = this.responsibleAdd;
                    this.participants = this.participantsAdd;
                    this.author = this.authorAdd;
                    if (this.uved && this.uved.id) {
                        this.uved_show = this.uved;
                    }
                    else {
                        this.uved_show = {};
                    }

                })
                .catch(err => {
                    this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async addCaseAdminToDeal() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.deal){
                if (this.deal.id){
                    formData.append('deal', this.deal.id)
                }
                else {
                    formData.append('deal', this.deal)
                }
            }


            if (this.$route.params.id) {
                formData.append('case_admin_uuid', this.$route.params.id)
            }
            // Add
            await this.$http
                .post('admin/case_admin_deal', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('case_admin_has_been_updated'))
                    this.dialogLinkDeal = false;
                    this.getCaseAdmin();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },



        handleClick(click, msg, date) {
            switch (click) {
                case 'delete':
                    this.deleteMessages(msg, date)
                    break;
                case 'reply' :
                    this.replyOpen(msg)
                    break;
            }
        },
        replyOpen(msg) {
            this.reply_uuid = msg.uuid
            this.reply_msg = msg.comment;
            this.reply_name = msg.name;
            this.toolbar_reply = true
        },
        replyClose() {
            this.scrollCard()
            this.reply_uuid = null
            this.reply_name = null
            this.toolbar_reply = false
            this.reply_msg = {}
        },
        scrollCard() {
            this.$nextTick(() => {
                // Прокрутка до нижней части после обновления списка
                if (this.$refs.scrollContainer !== undefined) {
                    const scrollContainer = this.$refs.scrollContainer.$el;
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }
            });
        },
        clearCompanies() {
            if (!this.company) {
                this.companyItems = []
            }
        },
        redirectToCompany(val) {
            if (val) {
                this.$router.push({
                    name: 'company.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data
                    })
                    .catch(err => {
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })
            }
        },
        async getAddDeals(str) {
            if (str) {
                this.loadingDeals = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.deal = str
                }
                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealAddItems = res.body.data
                    })
                    .catch(err => {
                        this.dealAddItems = []
                    })
                    .finally(end => {
                        this.loadingDeals = false
                    })
            }
        },

        showTask(item) {
            //  window.location.href = '/task/'+item.uuid+'/show';
            this.$router.push({
                name: 'subtask.show',
                params: {
                    id: item.uuid
                },
            })
            this.forceRerender()
        },

        linkSubTask() {
            this.dialogLinkTask = true;
        },
        async addCaseAdminToTask() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.$route.params.id) {
                formData.append('case_admin_uuid', this.$route.params.id)
            }
            if(this.task){
                if(this.task.id){
                    formData.append(`task`, this.task.id)
                }
                else{
                    formData.append(`task`, this.task)
                }
            }

            // Add
            await this.$http
                .post('admin/case_admin_task', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('case_admin_has_been_updated'))
                    this.dialogLinkTask = false;
                    this.getCaseAdmin()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        clearTasks() {
            if (!this.task) {
                this.taskAddItems = []
            }
        },
        async getTasks(str) {
                this.loadingTasks = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.task = str
                }
                await this.$http
                    .get("admin/task", {
                        params: params,
                    })
                    .then(res => {
                        this.taskAddItems = res.body.data
                    })
                    .catch(err => {
                        this.taskAddItems = []
                    })
                    .finally(end => {
                        this.loadingTasks = false
                    })
        },
        clearEmptyTaskItems(items) {
            this.task = null
        },
        linkDeal() {
            this.dialogLinkDeal = true;
        },
        ImageViewClose() {
            this.dialog_image_view = false;
            this.image_view = {};
        },
        ImageView(msg) {
            this.dialog_image_view = true;
            this.image_view = msg;
        },


        async saveTask() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.number) {
                formData.append('number', this.number)
            }

            if (this.text) {
                formData.append('text', this.text)
            }

            if (this.company) {
                if (this.company.id) {
                    formData.append('company', this.company.id)
                } else {
                    formData.append('company', this.company)
                }
            }

            if (this.task_status) {
                if (this.task_status.id) {
                    formData.append('task_status', this.task_status.id)
                } else {
                    formData.append('task_status', this.task_status)
                }
            }

            if (this.responsible && this.responsible.length > 0) {
                for (let i in this.responsible) {
                    if (this.responsible[i].id !== undefined && this.responsible[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsible[i].id)
                    }
                }
            }

            if (this.auditor && this.auditor.length > 0) {
                for (let i in this.auditor) {
                    if (this.auditor[i].id !== undefined && this.auditor[i].id > 0) {
                        formData.append(`participants[${i}]`, this.auditor[i].id)
                    }
                }
            }

            if (this.files && this.files.length > 0) {
                let is_image = false;
                for (let i in this.files) {
                    is_image = this.mimeTypeImage(this.files[i].type);
                    formData.append(`type_mime[${i}]`, this.files[i].type)
                    formData.append(`size[${i}]`, this.files[i].size)
                    formData.append(`file_name[${i}]`, this.files[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)
                    formData.append(`upfiles[${i}]`, this.files[i])
                }
            }

            if (this.$route.params.id && this.task_uuid) {
                await this.$http
                    .put(`admin/task/${this.task_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_updated'))

                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/task', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_added'))
                        // if (res && res.body && res.body.data && res.body.data.id) {
                        //       this.$router.push({
                        //         name: 'task.edit',
                        //         params: {
                        //             id: res.body.data.id
                        //         }
                        //     })
                        // } else {
                        //     this.$router.push({
                        //         name: 'task'
                        //     })
                        // }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async CaseAdminTextUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.description) {
                formData.append('text', this.description)
            }

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/case_admin/${this.$route.params.id}/field`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('case_admin_has_been_updated'))
                        this.description_edit = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        downloadFile(data) {
            fetch(data.url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
        downloadFileMessage(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
        dropFile(event) {
            this.files = Array.from(event.dataTransfer.files);
            this.previewFiles();
        },
        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },
        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },
        deleteAccompanyingDocumentFile(item) {
            let index = this.accompanying_document_urls.indexOf(item)
            this.accompanying_document_urls.splice(index, 1)
        },
        deleteAccompanyingDocuments(item) {
            let index = this.accompanying_documents_preview.indexOf(item)
            this.accompanying_documents_preview.splice(index, 1)
            if (this.accompanying_documents_preview.length === 0) {
                this.accompanying_documents_dialog = false;
                this.accompanying_documents_preview = [];
                this.accompanying_documents = []
            }
        },
        async saveTaskFile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.edit_task_type) {
                formData.append('task_type', this.edit_task_type)
            }

            if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                let is_image = false;
                for (let i in this.accompanying_documents_preview) {
                    is_image = this.mimeTypeImage(this.accompanying_documents_preview[i].type);
                    formData.append(`type_mime[${i}]`, this.accompanying_documents_preview[i].type)
                    formData.append(`size[${i}]`, this.accompanying_documents_preview[i].size)
                    formData.append(`file_name[${i}]`, this.accompanying_documents_preview[i].name)
                    formData.append(`access[${i}]`, this.accompanying_documents_preview[i].access)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    this.accompanying_documents_preview[i].blob = await this.convertBlobToBase64(this.accompanying_documents_preview[i].blob)
                    if (this.accompanying_documents_preview[i].blob) {
                        let blob = this.dataURL64toBlob(this.accompanying_documents_preview[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.accompanying_documents_preview[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                }
            }

            await this.$http
                .put(`admin/case_admin/file/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))

                    if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                        for (let i in this.accompanying_documents_preview) {
                            let author = {
                                "author": {
                                    "name": this.$auth.user().name
                                }
                            };
                            Object.assign(this.accompanying_documents_preview[i], author);
                            this.accompanying_document_urls.push(this.accompanying_documents_preview[i])
                        }
                        this.accompanying_documents_dialog = false;
                        this.accompanying_documents_preview = [];
                        this.accompanying_documents = []
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async deleteFileServer(item) {
            if (confirm(this.$t('want_to_delete_file'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/case_admin/file/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('file_has_been_deleted'))
                        this.deleteAccompanyingDocumentFile(item)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        CaseAdminMessagesWebsocket() {
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen(`.Event.CaseAdminChat.${this.$route.params.id}`, (event) => {
                    //Если пришло добавление собщение
                    if (event && event.id && event.id > 0) {
                        if (event.comment && event.comment.type) {
                            // if(event.comment.type === 'text') {

                            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                                const newMessage = {
                                    [this.$moment().format('YYYY-MM-DD')]:
                                        [
                                            event
                                        ]
                                }
                                this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                            } else {
                                let message_date = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')];
                                let uuidToFind = event.uuid;
                                let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                                if (typeof foundObject != "undefined") {
                                    foundObject.id = event.id;
                                    foundObject.comment = event.comment;
                                    foundObject.delivered = event.delivered;
                                    foundObject.uuid = event.uuid;
                                    foundObject.created_at = event.created_at;
                                } else {
                                    message_date.push(event);
                                }
                            }
                        }

                    } else if (event && event.type && event.type === 'delete') {
                        //Если пришло удаление собщение
                        if (event.id_array && event.id_array > 0 && event.date) {
                            this.chatMessagesDate[event.date].splice(event.id_array, 1);
                        }
                    }
                    this.scrollCard();

                });
        },
        handleEnterKey(event) {
            if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                this.SentMessage();
            } else {
                event.preventDefault();
                if (!event.shiftKey) {
                    this.text += '\n';
                }
            }
        },
        async SentMessage() {
            if (typeof this.text === 'undefined' || this.text === null || this.text === '') {
                return;
            }
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            let comment_uuid = this.$uuid.v4();
            formData.append('comment_uuid', comment_uuid)
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.reply_uuid) {
                formData.append('reply_uuid', this.reply_uuid)
            }
            if (this.text) {
                formData.append('message', this.text)
            }
            formData.append('case_admin_uuid', this.$route.params.id)
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            // Add
            await this.$http
                .post('admin/case_admin_message', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    let replyMessage = {}
                    if (this.reply_uuid) {
                        if (this.reply_msg.type === 'text') {
                            replyMessage = {
                                "type": this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid": this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                            }
                        } else {
                            replyMessage = {
                                "type": this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid": this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                                "size": this.reply_msg.size,
                                "type_mime": this.reply_msg.type_mime,
                                "file_type": this.reply_msg.file_type,
                                "file_name": this.reply_msg.file_name,
                            }
                        }

                    }

                    if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {

                        const newMessage = {
                            [this.$moment().format('YYYY-MM-DD')]:
                                [
                                    {
                                        "id": Number(new Date().getTime()) + 1,
                                        "uuid": comment_uuid,
                                        "me": true,
                                        "comment": {"type": "text", "value": this.text},
                                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                        "delivered": false,
                                        "name": this.$auth.user().name,
                                        "phone": this.$auth.user().phone,
                                        "photo": this.$auth.user().photo,
                                        "reply": replyMessage
                                    }
                                ]
                        }
                        this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                    } else {
                        this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                            "id": Number(new Date().getTime()) + this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1,
                            "uuid": comment_uuid,
                            "me": true,
                            "comment": {"type": "text", "value": this.text},
                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                            "delivered": false,
                            "name": this.$auth.user().name,
                            "phone": this.$auth.user().phone,
                            "photo": this.$auth.user().photo,
                            "reply": replyMessage
                        });
                    }
                    this.text = ''
                    this.count_messages++;
                    this.replyClose();

                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async getMessages() {
            this.loadingMessages = true;
            let params = {};
            params.case_admin_uuid = this.$route.params.id;
            await this.$http
                .get("admin/case_admin_message", {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.chatMessagesDate = res.body.data
                    } else {
                        this.chatMessagesDate = []
                    }
                    this.scrollCard();
                })
                .catch(err => {
                    this.chatMessagesDate = []
                    this.$toastr.error(this.$t('failed_to_get_list_messages'))
                })
                .finally(end => {
                    this.loadingMessages = false
                })
        },
        async SentMessageFile() {
            var _this = this;
            let formData, blob;
            this.dialog_file = false;
            this.documents = []
            let is_image = false;


            let id_array = 0;
            for (const file of this.document_urls) {

                let replyMessage = {}
                if (this.reply_uuid) {
                    if (this.reply_msg.type === 'text') {
                        replyMessage = {
                            "type": this.reply_msg.type,
                            "name": this.reply_name,
                            "reply_uuid": this.reply_msg.uuid,
                            "value": this.reply_msg.value,
                        }
                    } else {
                        replyMessage = {
                            "type": this.reply_msg.type,
                            "name": this.reply_name,
                            "reply_uuid": this.reply_msg.uuid,
                            "value": this.reply_msg.value,
                            "size": this.reply_msg.size,
                            "type_mime": this.reply_msg.type_mime,
                            "file_type": this.reply_msg.file_type,
                            "file_name": this.reply_msg.file_name,
                        }
                    }

                }
                let comment_uuid = this.$uuid.v4();
                is_image = this.mimeTypeImage(file.type);
                if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    id_array = 1;
                    const newMessage = {
                        [this.$moment().format('YYYY-MM-DD')]:
                            [
                                {
                                    "id": Number(new Date().getTime()) + 1,
                                    "uuid": comment_uuid,
                                    "me": true,
                                    "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name": file.name, "size": file.size},
                                    "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                    "delivered": false,
                                    "name": this.$auth.user().name,
                                    "phone": this.$auth.user().phone,
                                    "photo": this.$auth.user().photo,
                                    "reply": replyMessage
                                }
                            ]
                    }
                    this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                } else {
                    id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1;
                    this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                        "id": Number(new Date().getTime()) + (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length) + 1,
                        "uuid": comment_uuid,
                        "me": true,
                        "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name": file.name, "size": file.size},
                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                        "delivered": false,
                        "name": this.$auth.user().name,
                        "phone": this.$auth.user().phone,
                        "photo": this.$auth.user().photo,
                        "reply": replyMessage
                    });
                }

                formData = new FormData();
                if (this.reply_uuid) {
                    formData.append('reply_uuid', this.reply_uuid)
                }
                formData.append('comment_uuid', comment_uuid)
                formData.append('id_array', id_array)
                formData.append('case_admin_uuid', this.$route.params.id)
                formData.append('type_mime', file.type)
                formData.append('size', file.size)
                formData.append('file_name', file.name)
                formData.append('is_image', is_image ? 1 : null)
                file.blob = await this.convertBlobToBase64(file.blob)
                if (file.blob) {
                    blob = this.dataURL64toBlob(file.blob)
                    if (blob) {
                        formData.append('file', blob, file.type)
                    }
                }

                // Add
                this.$http
                    .post('admin/case_admin_message/file', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        var index = this.chatMessages.length + 1
                        if (typeof this.chatMessages[index] != "undefined") {
                            this.chatMessages[index].delivered = true;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('page_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        var index = this.chatMessages.length + 1
                        if (typeof this.chatMessages[index] != "undefined") {
                            this.chatMessages[index].delivered = true;
                        }
                    })


            }
            this.replyClose()
            this.document_urls = [];
            this.count_messages++;
        },
        async deleteMessages(msg, date) {
            this.loading = true;
            let params = {};
            let idx = this.chatMessagesDate[date].indexOf(msg);
            params.case_admin_uuid = this.$route.params.id;
            params.message_uuid = msg.uuid;
            params.date = date;
            if (idx !== -1) {
                params.id_array = idx;
            }

            await this.$http
                .delete(`admin/case_admin_message/${this.$route.params.id}`, {
                    params: params,
                })
                .then(res => {

                    //let index = this.chatMessagesDate[date].findIndex(el => el.uuid === "4fb4bc6b-9a77-40fb-99aa-865c73a4b004");
                    if (idx !== -1) {
                        this.chatMessagesDate[date].splice(idx, 1);
                    }
                    this.$toastr.success(this.$t('message_has_been_deleted'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_deleted'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        handleFilesSaved(files) {
            let i = 0;
            let data = {}
            files.forEach((file) => {
                data = {}
                data.id = i
                data.access = file.access
                data.url = file.url
                data.name = file.name
                data.blob = file.blob
                data.size = file.size
                data.type = file.type
                this.accompanying_documents.push(data)
                this.accompanying_documents_preview.push(data)
                i++
            })
            this.saveTaskFile();
        },
        accompanyingDocumentsPreviewFiles() {
            let i = 0;
            let data = {}
            this.accompanying_documents.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.accompanying_documents_preview.push(data)
                i++
            })
            this.accompanying_documents_dialog = true
        },

        async editStatusCaseAdmin(confirm) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }
            formData.append('confirm', confirm)
            if (this.reject_text) {
                formData.append('reject_text', this.reject_text)
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            await this.$http
                .put(`admin/case_admin/${this.$route.params.id}/status/edit`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.getCaseAdmin();
                    this.is_agreed = res.body.data.is_agreed
                    this.dialogEditStatusCaseAdmin = false;
                    this.reject_text = null;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        showCompany(uuid) {
            this.$router.push({
                name: 'company.show',
                params: {
                    id: uuid
                }
            })
        },
        openDiapogDate() {
            this.displayDateTimePicker = true;
        },
        closeDialogDateTimePicker() {
            this.displayDateTimePicker = false;
        },

        async delegate(users) {
            // выбранных пользователей ставим в ответственных
            this.$http.put(`admin/task/${this.task_uuid}`, {
                responsible: users.map((id) => {
                    return {
                        id
                    }
                })
            }).then(res => {
                this.$toastr.success(this.$t('task_has_been_updated'))
                this.getTask();
            }).catch(err => {
                this.$toastr.error(this.$t('task_has_not_been_updated'))
            })
            // ответвенного ставим в наблюдатели
        },

        onDelegate() {
            var formData = new FormData()

            // текущего пользователя убрать из ответственных
            let responsibles = this.responsible.filter((item) => {
                return item.id !== this.$auth.user().id
            }).map((item) => {
                return item.id
            })

            // добавляем делегируемого пользователя в ответственного
            this.delegatingToUsers.map(item => {
                if(!responsibles.includes(item)) {
                    responsibles.push(item);
                }
            })

            let i = 0;
            responsibles.map(item => {
                formData.append(`responsibles[${i}]`, item.id);
                i++;
            })

            // текущего пользователя в наблюдатели
            let participants = this.participants.map(item => {
                return item.id
            });

            if(!participants.includes(this.$auth.user().id)) {
                participants.push(this.$auth.user().id)
            }

            i = 0
            participants.map(item => {
                formData.append(`participants[${i}]`, item);
                i++;
            });

            // formData.append('responsibles', responsibles);
            // formData.append('participants', participants);
            formData.append('do_not_delete', 1);
            formData.append('task_uuid', this.$route.params.id)
            formData.append('send_messages', 1)
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            this.$http.put(`admin/task_participant`, formData).then(res => {
                this.$toastr.success(this.$t('task_has_been_updated'))
                this.getTask();
                this.dialogDelegate = false;
            }).catch(err => {
                this.$toastr.error(this.$t('task_has_not_been_updated'))
            })

        },

        async approvalSheet(){
            var _this = this;
            this.loading = true


            this.loading = true
            let params = {}
            if (this.language) {
                params.language =  this.language
            }
            if (this.$route.params.id) {
                params.case_admin_uuid =  this.$route.params.id
            }


            await this.$http
                .get(`admin/approval_sheet/case_admin`, {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.url){
                        this.openFile(res.body.data)
                        //this.downloadFile(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })



        },
    }
}
</script>

<style scoped>
.v-card--link:before {
    background: none;
}

>>> .heightTable td {
    height: 50px !important;
}

.select_border_none >>> .v-input__slot::before {
    border-style: none !important;
}
</style>
<style lang="scss">
.approval-sheet__item {
    @media screen and(max-width: 992px) {
        &.v-list-item {
            flex-direction: column;
            min-height: auto;
            padding-left: 0;
            padding-right: 0;
        }

        .approval-sheet__desc {
            width: 100%;
        }

        .approval-sheet__btns {
            width: 100%;
            flex-direction: row;

            .v-btn {
                flex-basis: auto;
            }
        }
    }
}

.task-document {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter / 2;
    border: 1px solid #eeeeee;
    flex-wrap: wrap;

    &.bordered {
        border-radius: 8px;
        padding: $grid-gutter / 2;;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.task-document__desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: $grid-gutter / 2;
}

.task-document__icon {
    width: 45px;
    height: 45px;
    margin-right: $grid-gutter / 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-document__text {
    flex-grow: 1;
}

.task-document__approval {
    margin-top: $grid-gutter / 2;
    padding-top: $grid-gutter / 2;
    border-top: 1px solid #ccc;
}

.approval {
    width: 100%;

    &:empty {
        display: none;
    }
}

.approval__actions {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: $grid-gutter / 2;
    flex-direction: row-reverse;

    & > * {
        flex-grow: 1;
    }
}

.approval__info {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter / 2;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .task-document {
        flex-direction: row;
    }

    .task-document__approval-info {
        flex-direction: row;
        gap: $grid-gutter / 2;
    }

    .approval-actions {
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }

    .task-document__desc {
        margin-bottom: 0;
    }
}
</style>

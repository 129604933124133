<template>
    <div>
        <v-icon
            :title="$t('add_file')"
            size="18"
            color="secondary"
            class="add_icon"
            @click="triggerFileInput"
            :disabled="disabled"
        >
            mdi-plus-circle-outline
        </v-icon>

        <v-file-input
            hide-input
            prepend-icon=""
            :label="$t('files')"
            v-model="files"
            id="files"
            multiple
            @change="previewFiles"
        ></v-file-input>

        <v-dialog
            v-model="filesDialog"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            max-width="500px"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('send_files', {count: filesPreview.length}) }}</span>
                    </div>
                    <div>
                        <v-icon @click="closeDialog">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text style="height: 300px;" class="pa-0">
                    <v-list subheader>
                        <v-list-item v-for="document in filesPreview" :key="document.id">
                            <v-list-item-icon>
                                <v-img
                                    v-if="isImage(document.type)"
                                    max-width="90"
                                    :alt="document.name"
                                    :src="document.url"
                                    max-height="60"
                                ></v-img>
                                <v-icon v-else x-large>mdi-file-document-outline mdi-48px</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ document.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ (document.size / 1000).toFixed(1) }} KB</v-list-item-subtitle>
                                <v-list-item-content>
                                    <SelectInput
                                        item-text="name"
                                        item-value="value"
                                        hide-details
                                        hide-label
                                        :items="accessOptions"
                                        v-model="document.access"
                                        :name="document.access"
                                        :id="document.access"
                                        :hideLabel="true"
                                        :label="$t('file_access')"
                                    ></SelectInput>
                                </v-list-item-content>
                            </v-list-item-content>

                            <v-list-item-icon class="d-flex align-self-start mt-2">
                                <v-btn icon @click="deleteFile(document)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveFiles">
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>



<script>
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: "UploadFiles",
    components: {
        SelectInput,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            files: [],
            filesPreview: [],
            filesDialog: false,
            accessOptions: [
                {name: this.$t('read_write'), value: 'write'},
                {name: this.$t('read_only'), value: 'read'}
            ]
        };
    },
    methods: {
        triggerFileInput() {
            document.getElementById('files').click();
        },
        previewFiles() {
            this.filesPreview = this.files.map((file, index) => ({
                id: index,
                url: URL.createObjectURL(file),
                name: file.name,
                blob: file,
                size: file.size,
                type: file.type,
                access: 'write'
            }));
            this.filesDialog = true;
        },
        isImage(type) {
            return type.startsWith('image/');
        },
        deleteFile(file) {
            this.filesPreview = this.filesPreview.filter(f => f.id !== file.id);
            this.files = this.files.filter(f => f.name !== file.name);
        },
        closeDialog() {
            this.filesDialog = false;
            this.filesPreview = [];
            this.files = [];
        },
        saveFiles() {
            this.$emit('files-saved', this.filesPreview);
            this.closeDialog();
        },
    },
};
</script>

<style scoped>
.add_icon {
    cursor: pointer;
}

.dialog_body {
    max-height: 400px;
}
</style>



